function startSlideshow() {
	const imgWrappers = Array.from(document.querySelectorAll('.room-img-wrapper-js'));
	imgWrappers.forEach((imgWrapper) => {
		let isPaused = false;
		let imgs = imgWrapper.querySelectorAll('.img-room-js');
		const controlsWrapper = imgWrapper.querySelector('.img-controls-wrapper-js');

		if(imgs.length > 1) {
			for(let i = 0; i < imgs.length; i++) {
				let btn = document.createElement('button');
				btn.className = 'img-control img-control-js';
				btn.type = 'button';
				if(i === 0) {
					btn.className += ' --active';
				}
				controlsWrapper.appendChild(btn);
				btn.addEventListener('click', (event) => {
					slideImages(imgs, i);

					isPaused = true;
					setTimeout(() => {
						isPaused = false;
					}, 5000);
				})
			}
		}
	})
}

function slideImages(imgs, counter) {
	let controlWrapper = imgs[0].parentNode.querySelector('.img-controls-wrapper-js');
	let activeControl = controlWrapper.querySelector('.--active');
	activeControl.classList.remove('--active');

	if(counter === null) {
		counter = parseInt(imgs[0].parentNode.getAttribute('data-counter'));
	}
	if(isNaN(counter)) {
		counter = 0
	}

	for(let j = 0; j < imgs.length; j++) {
		imgs[j].style.transform = `translateX(${counter * (-100)}%)`;
	}
	controlWrapper.children[counter].classList.add('--active');

    imgs[0].parentNode.dataset.counter =
		counter < imgs.length - 1 ? counter + 1 : 0;
}
